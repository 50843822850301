/* actions for loading the necessary data from the database into the global state */

export function DatabaseReducer(state, action) {
    switch (action.type) {
      case 'load_departments': {
        return {
          ...state,
          departments: action.newDepartments,
        };
      }
      case 'load_employees': {
        return {
          ...state,
          employees: action.newEmployees
        };
      }
      case 'load_customers': {
        return {
          ...state,
          customers: action.newCustomers
        };
      }
      case 'load_companies': {
        return {
          ...state,
          companies: action.newCompanies,
          calibration_companies: action.newCompanies.filter(company => company.types.includes("calibration_company")),
          training_companies: action.newCompanies.filter(company => company.types.includes("training_company"))
        };
      }
      case 'load_company_certificates': {
        return {
          ...state,
          company_certificates: action.newCompanyCertificates,
          calibration_company_certificates: action.newCompanyCertificates.filter(cert => cert.company.types.includes("calibration_company")),
          training_company_certificates: action.newCompanyCertificates.filter(cert => cert.company.types.includes("training_company")),
        };
      }
      case 'load_company_contacts': {
        return {
          ...state,
          company_contacts: action.newCompanyContacts,
        };
      }
      case 'load_error_types': {
        return {
          ...state,
          error_types: action.newErrorTypes
        };
      }
      case 'load_groups': {
        return {
          ...state,
          groups: action.newGroups
        };
      }
      case 'load_suppliers': {
        return {
          ...state,
          suppliers: action.newSuppliers
        };
      }
      case 'load_preventions': {
        return {
          ...state,
          preventions: action.newPreventions
        };
      }
      case 'load_offenders': {
        return {
          ...state,
          offenders: action.newOffenders
        };
      }
      case 'load_error_records': {
        return {
          ...state,
          error_records_int: action.newErrorRecords.filter(error_record => error_record.type == 'Interná chyba'),
          error_records_ext: action.newErrorRecords.filter(error_record => error_record.type == 'Externá chyba'),
          error_records_rek: action.newErrorRecords.filter(error_record => error_record.type == 'Reklamácia'),
        };
      }
      case 'load_documentation': {
        return {
          ...state,
          documentation: action.newDocumentation
        };
      }
      case 'load_self_control': {
        return {
          ...state,
          self_control: action.newSelfControl
        };
      }
      case 'load_measurement_protocol': {
        return {
          ...state,
          measurement_protocol: action.newMeasurementProtocol
        };
      }
      case 'load_ndt_protocol': {
        return {
          ...state,
          ndt_protocol: action.newNDTProtocol
        };
      }
      case 'load_annealing_protocol': {
        return {
          ...state,
          annealing_protocol: action.newAnnealingProtocol
        };
      }
      case 'load_measure_complexities': {
        return {
          ...state,
          measure_complexities: action.newMeasureComplexities
        };
      }
      case 'load_priority_departments': {
        let final_employees = [];
        for(let i = 0;i<action.newPriorityDepartments.length;i++){
          var abbreviation_employees = [];
          
          for(let j = 0;j<action.newPriorityDepartments[i].departments.length;j++){
            abbreviation_employees = [...abbreviation_employees, ...action.newPriorityDepartments[i].departments[j].employees];
          }
          final_employees = [...final_employees,{
            abbreviation: action.newPriorityDepartments[i].abbreviation,
            id: action.newPriorityDepartments[i].id,  
            employees: abbreviation_employees
          }];
        }
        return {
          ...state,
          priority_departments: action.newPriorityDepartments,
          priority_departments_employees: final_employees,
        };
      }
      case 'priority_departments_reload': {
        return {
          ...state,
          priority_departments_reload: action.value
        };
      }
      case 'load_priorities': {
        return {
          ...state,
          priorities: action.newPriorities
        };
      }
      case 'priorities_reload': {
        return {
          ...state,
          priorities_reload: action.value
        };
      }
      case 'load_control_records': {
        return {
          ...state,
          control_records: action.newControlRecords
        };
      }
      case 'load_users': {
        return {
          ...state,
          users: action.newUsers
        };
      }
      case 'load_roles': {
        return {
          ...state,
          roles: action.newRoles
        };
      }
      case 'load_favorites': {
        return {
          ...state,
          favorites: action.newFavorites
        };
      }
      case 'login_user': {
        return {
          ...state,
          user: action.newUser
        };
      }
      case 'load_last_used': {
        let last_used = JSON.parse(localStorage.getItem("favorites"));
        if(last_used==undefined)last_used=[];
        last_used = last_used.filter(item => item.user_id === state.user.id);
        if(last_used.length>10)last_used = last_used.slice(0,10);
        return {
          ...state,
          last_used: last_used
        };
      }
      case 'load_painting_intervals':{
        return {
          ...state,
          painting_intervals: action.newPaintingIntervals
        }
      }
      case 'load_painting_conditions':{
        return {
          ...state,
          painting_conditions: action.newPaintingConditions
        }
      }
      case 'load_weather':{
        return {
          ...state,
          weather: action.newWeather
        }
      }
      case 'load_gauges':{
        return {
          ...state,
          gauges: action.newGauges
        }
      }
      case 'load_gauge_calibrations':{
        return {
          ...state,
          gauge_calibrations: action.newGaugeCalibrations
        }
      }
      case 'load_calibration_companies':{
        return {
          ...state,
          calibration_companies: action.newCalibrationCompanies
        }
      }
      case 'load_training_companies': {
        return {
          ...state,
          training_companies: action.newTrainingCompanies
        }
      }
      case 'load_training_company_contacts': {
        return {
          ...state,
          training_company_contacts: action.newTrainingCompanyContacts
        }
      }
      case 'load_training_types': {
        return {
          ...state,
          training_types: action.newTrainingTypes
        }
      }
      case 'load_training_cards': {
        return {
          ...state,
          training_cards: action.newTrainingCards
        }
      }
      case 'load_training_employees': {
        return {
          ...state,
          training_employees: action.newTrainingEmployees
        }
      }
      case 'load_training_templates': {
        return {
          ...state,
          training_templates: action.newTrainingTemplates
        }
      }
      case 'load_training_company_certificates':{
        return {
          ...state,
          training_company_certificates: action.newTrainingCompanyCertificates
        }
      }
      case 'load_training_invitations':{
        return {
          ...state,
          training_invitations: action.newTrainingInvitations
        }
      }
      case 'load_training_invitation_templates':{
        return {
          ...state,
          training_invitation_templates: action.newTrainingInvitationTemplates
        }
      }
      case 'create_new_tab': {
        const newTab = {
          value: `${state.tabIndex}`,
          label: action.name
        }
        const newPanel = {
          value: `${state.tabIndex}`,
          child: action.page,
          props: action.props || null
        }
        let all_last_used = JSON.parse(localStorage.getItem("favorites"));
        if(all_last_used==undefined)all_last_used=[];
        let my_last_used = all_last_used.filter(item => item.user_id === state.user.id);
        let not_my_last_used = all_last_used.filter(item => item.user_id !== state.user.id);
        my_last_used = my_last_used.filter(item => item.name !== action.name && item.page !== action.page);
        if(my_last_used.length>10)my_last_used = my_last_used.slice(0,10);
        var new_last_used,new_my_last_used;
        if(newPanel?.props?.not_last_used){
          new_last_used = [...my_last_used,...not_my_last_used];
          new_my_last_used = [...my_last_used];
        }
        else{
          new_last_used = [{name:action.name,page:action.page,user_id: state.user.id},...my_last_used,...not_my_last_used];
          new_my_last_used = [{name:action.name,page:action.page,user_id: state.user.id},...my_last_used];
        }
        
        localStorage.setItem("favorites",JSON.stringify(new_last_used));
        return {
          ...state,
          tabs: state.tabs.concat(newTab),
          panels: state.panels.concat(newPanel),
          tabIndex: state.tabIndex+1,
          selectedTab: `${state.tabIndex}`,
          last_used: new_my_last_used
        };
      }
      case 'close_tab': {
        const tabs = state.tabs.filter(x => x.value !== action.id);
        const panels = state.panels.filter(x => x.value !== action.id);
        console.log("TABS ",tabs);
        console.log("PANELS ",panels);
        var selectedTab = ""+(panels[panels.length-1].value) + "";
        if(selectedTab=='2')selectedTab='1';
        return {
          ...state,
          tabs: tabs,
          panels: panels,
          selectedTab: selectedTab
        };
      }
      case 'change_tab': {
        return {
          ...state,
          selectedTab: action.name
        };
      }
      case 'init_tabs_panels': {
        return {
          ...state,
          tabs:[{value: `1`,label: "HomePage"},{value: `2`,label: "ProfilePage"}],
          panels:[{value: `1`,child: "HomePage"},{value: `2`,child: "ProfilePage"}],
          tabIndex: 3,
          selectedTab: '1',
        };
      }
      /*case 'add_to_favorites': {
        var found = null;
        function search(menu,title){
            menu.forEach(x => {
              if(x.children){
                search(x.children,title);
              }
              else{
                if(x.page===title){
                  found=x;
                  return;
                }
              }
            })
        }
        const filtered_tab = state.panels.filter(x => x.value === state.selectedTab)[0];
        search(menuItems,filtered_tab.child);
        console.log("SAVE VALUE ",found);
        return {
          ...state,
          favorites: [...new Set(state.favorites.concat(found))]
        };
      }*/

      case 'customers_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          customers_reload: action.value
        };
      }
      case 'suppliers_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          suppliers_reload: action.value
        };
      }
      case 'error_records_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          error_records_reload: action.value
        };
      }
      case 'departments_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          departments_reload: action.value
        };
      }
      case 'employees_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          employees_reload: action.value
        };
      }
      case 'roles_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          roles_reload: action.value
        };
      }
      case 'painting_intervals_reload': {
        return {
          ...state,
          painting_intervals_reload: action.value
        }
      }
      case 'painting_conditions_reload': {
        return {
          ...state,
          painting_conditions_reload: action.value
        }
      }
      case 'gauges_reload': {
        return {
          ...state,
          gauges: action.value
        }
      }
      case 'gauge_calibrations_reload': {
        return {
          ...state,
          gauge_calibrations: action.value
        }
      }
      case 'calibration_companies_reload': {
        return {
          ...state,
          calibration_companies: action.value
        }
      }
      case 'add_notification': {
        return {
          ...state,
          notifications: [...state.notifications,{id:action.value.id,message: action.value.message}],
        };
      }
      case 'clear_notification': {
        return {
          ...state,
          notifications: action.value,
        };
      }
      default:{
        throw Error('Unknown action: ' + action.type);
      }
    }
}
export default DatabaseReducer;