import React,{ createContext, useState ,useContext,useEffect,useRef} from "react";
import { getRequest,postRequest } from "../api/ApiController";
import { Dialog } from 'primereact/dialog';
import { DatabaseContext } from "../redux/DatabaseStore";
import AuthContext from "./AuthContext";
import {Bars} from 'react-loader-spinner';
import Pusher from 'pusher-js';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from "primereact/confirmdialog";
import { Sidebar } from 'primereact/sidebar';
import { Message } from 'primereact/message';
import { DeleteIconNtfs } from "../pages/svg/svgIcons";
import { CloseMess} from "../pages/svg/svgIcons";
import { useLocation } from 'react-router-dom';

/* Context, which serves to authenticate the user in the system */
const UtilContext = createContext();
export const UtilProvider = {};

export const UtilContextProvider = ({ children }) => {
  
    const { user } = useContext(AuthContext);
    const [state,dispatch] = useContext(DatabaseContext);

    const [dialogText,setDialogText] = useState(""); 
    const [visible,setVisible] = useState(false);
    const [visibleNotifications,setVisibleNotifications] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");
    const [favoriteName,setFavoriteName] = useState("");
    const [favoritePage,setFavoritePage] = useState("");
    const location = useLocation();

    const toast = useRef(null);

    UtilProvider.setVisible = setVisible;
    UtilProvider.toast = toast;

    async function fetchPriorities(message){
        getRequest("/api/priorities").then((response) => {
            //if(JSON.stringify(state.priorities) !== JSON.stringify(response.data)){
                dispatch({type:'load_priorities',newPriorities:response.data});
                dispatch({type:'priorities_reload',value:true});
                //toast.current.show({ severity: 'info', summary: 'Priority', detail: toastMessages[message],sticky:true });
            //}
        });
    }

    async function fetchDatafromDB(){
        //console.log("NACITAM",user,state.user);
        if(!state.user){
            setDialogText("Načítavam");
            setVisible(true);
            //console.log("DB STORE USER",user);
            dispatch({type:'login_user',newUser: user});
            dispatch({type:'load_last_used'});
        }
        if(user){
            await getRequest("/api/auth/startMobileRequest").then((response) => {
                dispatch({type:'load_employees',newEmployees:response.data.employees});
                dispatch({type:'load_departments',newDepartments:response.data.departments});
                dispatch({type:'load_companies',newCompanies:response.data.companies});
                dispatch({type:'load_company_certificates',newCompanyCertificates:response.data.company_certificates});
                dispatch({type:'load_company_contacts',newCompanyContacts:response.data.company_contacts});
                //dispatch({type:'load_favorites',newFavorites:response.data.favorites});
                /*if(user.permissions.includes('access users')){
                    dispatch({type:'load_roles',newRoles:response.data.roles});
                    dispatch({type:'load_users',newUsers:response.data.users});
                }*/
                if(user.permissions.includes('access priorities')){
                    dispatch({type:'load_priority_departments',newPriorityDepartments:response.data.priority_departments});
                    dispatch({type:'load_priorities',newPriorities:response.data.priorities});
                }
                if(user.permissions.includes('access otk-section1')){
                    //dispatch({type:'load_customers',newCustomers:response.data.customers});
                    dispatch({type:'load_error_types',newErrorTypes:response.data.error_types});
                    dispatch({type:'load_groups',newGroups:response.data.groups});
                    dispatch({type:'load_offenders',newOffenders:response.data.offenders});
                    dispatch({type:'load_preventions',newPreventions:response.data.preventions});
                    //dispatch({type:'load_suppliers',newSuppliers:response.data.suppliers});
                    dispatch({type:'load_measure_complexities',newMeasureComplexities: response.data.measure_complexities});
                    /*dispatch({type:'load_error_records',newErrorRecords:response.data.error_records});
                    dispatch({type:'load_documentation',newDocumentation:response.data.documentation});
                    dispatch({type:'load_self_control',newSelfControl: response.data.self_control});
                    dispatch({type:'load_measurement_protocol',newMeasurementProtocol: response.data.measurement_protocol});
                    dispatch({type:'load_ndt_protocol',newNDTProtocol: response.data.ndt_protocol});
                    dispatch({type:'load_annealing_protocol',newAnnealingProtocol: response.data.annealing_protocol});*/
                }
                if(user.permissions.includes('access painting')){
                    //dispatch({type:'load_painting_conditions',newPaintingConditions:response.data.painting_conditions});
                    dispatch({type:'load_painting_intervals',newPaintingIntervals:response.data.painting_intervals});
                    dispatch({type:'load_weather',newWeather:response.data.weather});
                }
                if(user.permissions.includes('access calibration')){
                    dispatch({type:'load_gauges',newGauges:response.data.gauges});
                    dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data.gauge_calibrations});
                    dispatch({type:'load_calibration_companies',newCalibrationCompanies:response.data.calibration_companies});
                }
                if(user.permissions.includes('access training')){
                    dispatch({type:'load_training_types',newTrainingTypes:response.data.training_types});
                    //dispatch({type:'load_training_companies',newTrainingCompanies:response.data.training_companies});
                    //dispatch({type:'load_training_company_certificates',newTrainingCompanyCertificates:response.data.training_company_certificates});
                    //dispatch({type:'load_training_company_contacts',newTrainingCompanyContacts:response.data.training_company_contacts});
                    dispatch({type:'load_training_cards',newTrainingCards:response.data.training_cards});
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data.training_employees});
                    //dispatch({type:'load_training_templates',newTrainingTemplates:response.data.training_templates});
                    dispatch({type:'load_training_invitations',newTrainingInvitations:response.data.training_invitations});
                    dispatch({type:'load_training_invitation_templates',newTrainingInvitationTemplates:response.data.training_invitation_templates});
                }
            });
        }
    }

    useEffect(() => {
        if (user !== null) {
            //TOTO FETCH ALL DATA fetchDatafromDB();
            //setSocket(io("https://imaschellinggroup.eu:4001"));
            //Pusher.logToConsole = true;
            var pusher = new Pusher('798100b9a135677e9c73', {
                cluster: 'eu'
            });
            var channel = pusher.subscribe('my-channel');
            channel.bind('my-event', function(data) {
                //alert(JSON.stringify(data));
                dispatch({type:'add_notification',value:data});
                if(data?.user_id!=user.id){
                    fetchPriorities(data.message);
                }
            });
            fetchDatafromDB().then(() => {
                setVisible(false);
            });
            return () => { 
                pusher.disconnect();
            };
        }  
      }, [user]);

    function openFavoriteDialog(favoriteName,favoritePage){
        setFavoriteName(favoriteName);
        setFavoritePage(favoritePage);
        setFavoriteVisible(true);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
            alert("Vyberte skupinu!");
            return;
        }
        else if(favoriteGroupValue=="nova"){
            if(favoriteValue==""){
                alert("Napíšte názov novez skupiny!");
                return;
            }
            else{
                //VYTVORIT SKUPINU AJ FAVORITA
                setFavoriteVisible(false);
                setVisible(true);
                let formData = new FormData();
                let new_group;
                formData.append("name", favoriteValue);
                postRequest(`/api/favorite_groups`, formData,true)
                .then((response) => {
                let formData2 = new FormData();
                formData2.append("name", favoriteName);
                formData2.append("page", favoritePage);
                formData2.append("favorite_group_id", response.data.id);
                postRequest(`/api/favorites`, formData2,true)
                .then((response) => {
                    getRequest("/api/my_favorite_groups").then((response) => {
                        dispatch({type:'load_favorites',newFavorites:response.data});
                        setVisible(false);
                    });
                }).catch((reason) => {
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
                }).catch((reason) => {
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
                setFavoriteName("");
                setFavoritePage("");
                setFavoriteGroupValue("");
                setFavoriteValue("");
            }
        }
        else{
            //VYTVORIT FAVORITA a pridat do skupiny
            setFavoriteVisible(false);
            setVisible(true);
            let formData2 = new FormData();
            formData2.append("name", favoriteName);
            formData2.append("page", favoritePage);
            formData2.append("favorite_group_id", favoriteGroupValue);
            postRequest(`/api/favorites`, formData2,true)
            .then((response) => {
                getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisible(false);
                });
            }).catch((reason) => {
                setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
            setFavoriteName("");
            setFavoritePage("");
            setFavoriteGroupValue("");
            setFavoriteValue("");
        }
    }
  

  return (
    <UtilContext.Provider value={{setDialogText,setVisible,openFavoriteDialog,toast, setVisibleNotifications}}>
      {children}
        <ConfirmDialog/>
        <Toast ref={toast} />
        <Dialog visible={visible} style={{ width: '85vw',height:'40vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
        <Bars   // Type of spinner
    height="40"
    width="40"
    color="rgb(69,10,10)"
    backgroundColor="white"
    strokeWidth={6}
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
/>
            <p className='text-2xl font-bold text-center w-52 text-red-950'>{dialogText}...</p>
        </Dialog>
        <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() =>  setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites?.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Sidebar visible={visibleNotifications} position="right" onHide={() => setVisibleNotifications(false)} className="w-60 md:w-60 lg:w-80">
        <div className="flex flex-row">
            <h2 className="font-semibold text-2xl pb-2 mr-4">Notifikácie</h2>
            <button onClick={() => dispatch({type:'clear_notification',value:[]})} className="w-40 h-8 bg-red-600 rounded-md border-red-600 font-semibold text-white hover:bg-red-500 focus:outline-none focus:bg-red-500 disabled:opacity-25" disabled={state.notifications?.length == 0 ? true : false}><div className="flex justify-center items-center"><DeleteIconNtfs className="mr-2"/></div></button>
        </div>
        <div className="card flex flex-col justify-content-start">
            {state.notifications.map((notification,key) => <Message severity="info" style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                    color: '#696cff'
                }}
                className="border-primary w-full justify-content-start mb-2"
                content={
                    <div className="flex flex-col align-items-start">
                    <div className="flex flex-row">
                        <span className="font-semibold ml-2">Priority</span>
                        <span className="flex justify-end w-full font-semibold ml-2"><button onClick={() => {let ntfs = state.notifications.filter(notif => notif.id != notification.id); dispatch({type:'clear_notification',value:ntfs})}} type="button"><CloseMess/></button></span>
                    </div>
                        <div className="ml-2">{notification.message}</div>
                    </div>
                }/>)}
        </div>
      </Sidebar>
    </UtilContext.Provider>
  );
};

export default UtilContext;